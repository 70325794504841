import logo from './images/twisklogo.png';
import './App.css';
import CountDown from './CountDown';
import CookieConsent from './CookieConsent';

// Function to get the time difference in seconds
function getTimeSinceLastVisit() {
  // Retrieve the stored visit time
  const visitTime = localStorage.getItem('visitTime');

  if (!visitTime) {
    console.log("No visit time found in localStorage.");
    return null;
  }

  // Parse the stored date and get the current date
  const lastVisitDate = new Date(visitTime);
  const currentDate = new Date();

  // Calculate the difference in milliseconds and convert to seconds
  const timeDifferenceInSeconds = Math.floor((currentDate - lastVisitDate) / 1000);

  return timeDifferenceInSeconds;
}

function App() {
  return (
    <div className="App">
      <header className="App-header">

        <a href='http://twisk.tech'>
        <img src={logo} className="App-logo" alt="logo"/>
        </a>
        <CountDown seconds={7938746-getTimeSinceLastVisit()}/>
        <CookieConsent/>
      </header>

    </div>
  );
}

export default App;
