import { useEffect, useState, useRef } from 'react';


const formatTime = (time) => {
    let hours = Math.floor(time / 3600);
    let minutes = Math.floor((time % 3600) / 60);
    let seconds = time % 60;
  
    if (hours < 10) hours = '0' + hours;
    if (minutes < 10) minutes = '0' + minutes;
    if (seconds < 10) seconds = '0' + seconds;
  
    return `${hours}:${minutes}:${seconds}`;
  };
  

export default function CountDown({ seconds }) {
  const [countdown, setCountdown] = useState(seconds);
  const timerId = useRef(null);

  useEffect(() => {
    timerId.current = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(timerId.current);
  }, []);

  useEffect(() => {
    if (countdown <= 0) {
      clearInterval(timerId.current);
      alert('Time is up!');
    }
  }, [countdown]); // Fixed the missing dependency array here

  return (
    <div className="countdown-container">
    <h2 className="countdown-title">Coming Soon</h2>
    <div className="countdown-display">
      {formatTime(countdown)}
    </div>
  </div>
  
  );
}
