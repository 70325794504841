import React, { useState, useEffect } from 'react';
import './CookieConsent.css'; // Import the CSS file

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consentGiven = localStorage.getItem('cookieConsent');
    if (!consentGiven) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    const visitTime = new Date().toISOString();
    localStorage.setItem('cookieConsent', 'true');
    localStorage.setItem('visitTime', visitTime);
    setIsVisible(false);
  };

  return (
    isVisible && (
      <div className="cookie-banner">
        <p className="cookie-text">
          This site uses cookies to improve your experience. By accepting, you consent to our cookie policy.
        </p>
        <button onClick={handleAccept} className="cookie-button">Accept</button>
      </div>
    )
  );
};

export default CookieConsent;
